/* start custom scss snippet */

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400|Open+Sans:300,400|Lato:300,400&display=swap');

body {
  font-family: "Source Sans Pro", "Open Sans", Lato, "Segoe UI", Helvetica, Arial, sans-serif;
  font-weight:300;
  letter-spacing: 0.01em;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  padding-top: 0.5em;
}

strong {
  font-weight: 600;
}

a {
  font-weight: 500;
}

a, a:link, a:visited {
  color: #cc4c4f;
}

/* Remove border from default iframe style - affects download link to curseforge */

iframe {
  border: none;
}

/* Lists in blog posts weren't indented as far as I like */

.js-article-content ul, .js-article-content ol {
    margin-left: 2rem;
}

.js-article-content ol li {
    padding-left: 0.4rem;
}

/* Separate favicon logo from site title in main site header */

.header__brand img {
  margin-right: 10px;
}

/* Figure captions */

figure {
  display: inline-block;
  margin-right: 1rem;
}

figcaption {
  text-align: center;
}

.image-caption {
  font-size: 0.85rem;
  position: relative;
  top: -1rem;
}

/* Styles for wrapping text around images */

.image-wrap-left {
  float: left;
  padding: 0.4rem 1rem 1rem 0;
}

.image-wrap-right {
  float: right;
  padding: 0.4rem 0 1rem 1rem;
}

/* Table styles */

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 1em auto;
  position: relative;
  text-align: left;
}

table td, table th {
  padding-left: 1rem;
}

table thead tr {
  height: 60px;
  background: #36304a;
  color: white;
}

table tbody tr {
  height: 50px;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

/*
tbody tr:nth-child(odd) {
  border-left: medium solid #f5f5f5;
  border-right: medium solid #f5f5f5;
}
*/

/*  The styles under page__content were copied out of _article-content.scss.
    I don't know why the theme author only added them to articles and not pages. 
    Irritating.*/

.page__content {
    
    /* Code */
    
    code.highlighter-rouge {
        padding: map-get($spacers, 1) map-get($spacers, 2);
        background-color: $text-background-color;
        border-radius: map-get($base, border-radius);
        span {
          padding: 0;
          margin: 0;
        }
      }

    .highlight > pre {
        padding: map-get($spacers, 1) map-get($spacers, 2);
        border-radius: map-get($base, border-radius);
    }
    
    /* Block quotes */
    
    blockquote {
        padding-left: map-get($spacers, 3);
        font-size: map-get($base, font-size-sm);
        color: $text-color-l;
        @include split-line(left, 4px, $border-color);
        p {
          margin: map-get($spacers, 2) 0;
        }
        code {
          font-size: 0.6rem;
        }
        & > :last-child {
          margin-bottom: 0;
        }
      }
}

/* Make header and footer static and don't let sidebar cover them */

.page__header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1; /* Prevents headings from appearing on top of header and TOC from making it so you can't click on links */
}

.page__footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.page__content {
  margin-top: 80px; /* Pushes it down below header */
  margin-bottom: 100px; /* Pushes it up above footer */
}

.page__sidebar {
  z-index: 0; /* Don't let side bar cover header/footer */
  margin-top: 80px; /* Brings it down below header */
  height: unset; /* Makes it not scroll */
}

.page__aside .toc-aside {
    padding-top: 5rem; /* Prevents right side TOC from hitting header */
}

ul.toc > li a {
  display: initial; /* Theme has it as "inline-block" which makes it so ellipsis won't appear */
}

/* end custom scss snippet */
